"use client";

import { motion } from "framer-motion";

import fans1 from "@/assets/images/fans_1.png";
import fans2 from "@/assets/images/fans_2.png";
import fans3 from "@/assets/images/fans_3.png";
import { Button } from "@/components/ui/button";
import { rubik } from "@/lib/fonts";
import { twMerge } from "@/lib/utils";
import Link from "next/link";
import Image from "next/image";

export const EnhanceFans = () => {
  return (
    <div className="flex flex-col md:flex-row justify-space-between w-full py-20  overflow-hidden">
      <div
        className={twMerge(
          "z-10 flex w-full max-w-full md:max-w-fit flex-col items-start justify-start gap-5",
          rubik.className,
        )}
      >
        <div className={twMerge("text-3xl md:text-4xl font-black italic")}>
          Enhance your fans&apos; experience.
        </div>

        <div className="border-l-4 border-brand_green px-5">
          <div className="typography py-0.5">
            Ticket Tree provides event organizers with value adding tools to
            optimize engagement within their fanbase.
          </div>
        </div>

          <Button variant="primary" className={twMerge("rounded-xl px-12 py-8 font-extrabold uppercase w-1/2", rubik.className)}>
              <Link href="/contact" passHref>
                Waitlist
              </Link>
          </Button>
      </div>

      <div className="hidden md:block">
        <div className="flex flex-col md:flex-row items-center">
          <motion.div
            whileHover={{ zIndex: 40 }}
            className="w-[200px] h-[272px] relative z-20"
          >
            <motion.div
              whileHover={{ scale: 1.2 }}
              className="md:absolute w-[272px] h-[272px] md:left-x/2"
            >
              <Image
                src={fans1.src}
                alt="Fans 1"
                width="272"
                height="272"
                className="block rounded-full border-tDarkGray border-[18px]"
              />
            </motion.div>
          </motion.div>
          <motion.div
            whileHover={{ zIndex: 40 }}
            className="w-[200px] h-[272px] relative z-10"
          >
            <motion.div
              whileHover={{ scale: 1.2 }}
              className="md:absolute w-[272px] h-[272px] md:left-x/2"
            >
              <Image
                src={fans2.src}
                alt="Fans 2"
                width="272"
                height="272"
                className="block rounded-full border-tDarkGray border-[18px]"
              />
            </motion.div>
          </motion.div>
          <motion.div
            whileHover={{ zIndex: 40 }}
            className="w-[200px] h-[272px] relative z-0"
          >
            <motion.div
              whileHover={{ scale: 1.2 }}
              className="md:absolute w-[272px] h-[272px] md:left-x/2"
            >
              <Image
                src={fans3.src}
                alt="Fans 3"
                width="272"
                height="272"
                className="block rounded-full border-tDarkGray border-[18px]"
              />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
