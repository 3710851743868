"use client";

import Head from "next/head";

import AboutUs from "@/components/AboutUs";
import Features from "@/components/Features";
import Header from "@/components/Header";
import { SnapToSection } from "@/components/SnapToSection";
import Footer from "@/components/Footer";
import { EnhanceFans } from "@/components/EnhanceFans";

export default function Home() {
  return (
    <>
      <Head>
        <title>Ticket Tree</title>
        <meta property="og:title" content="My page title" key="title" />
      </Head>

      <main className="relative z-10 max-h-screen max-w-screen">
        <SnapToSection>
          <Header />
        </SnapToSection>

        <SnapToSection className="max-w-7xl mx-auto flex flex-col items-center justify-center">
          <Features />
        </SnapToSection>

        <SnapToSection className="max-w-7xl mx-auto flex flex-col items-center justify-center">
          <AboutUs />
        </SnapToSection>

        <SnapToSection className="mx-4">
          <EnhanceFans />
        </SnapToSection>

        <SnapToSection className="">
          <Footer />
        </SnapToSection>
      </main>
    </>
  );
}
