import Link from "next/link";

import FansImage from "@/assets/images/fans.png";
import StadiumImage from "@/assets/images/stadium.png";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { rubik } from "@/lib/fonts";
import { twMerge } from "@/lib/utils";
import Image from "next/image";
const AboutUs = () => {
  return (
    <div className="w-full h-screen py-5 hidden md:block">
      <div className="flex flex-col gap-4 items-start">
        <Card className="rounded-3xl">
          <div className="flex flex-col">
            <AboutUsCard imageSrc={FansImage.src}>
              <AboutUsHeading>Who we are</AboutUsHeading>

              <AboutUsDescription>
                We&apos;re a group of dedicated fans committed to
                revolutionizing live event experiences. Today&apos;s ticketing
                processes are often confusing and pricey. Our goal is to
                simplify this journey and provide fans with unparalleled value.
              </AboutUsDescription>

              <AboutUsButton href="/about" >Learn More</AboutUsButton>
            </AboutUsCard>

            <AboutUsCard
              imageSrc={StadiumImage.src}
              className="md:flex-row-reverse h-auto"
            >
              <AboutUsHeading>What we do</AboutUsHeading>

              <AboutUsDescription>
                Our blockchain-powered platform specializes in secure ticket
                sales and enhances fan engagement by allowing event managers to
                airdrop exclusive highlights to ticket holders.
              </AboutUsDescription>

              <AboutUsButton href="/about">Learn More</AboutUsButton>
            </AboutUsCard>
          </div>
        </Card>
      </div>
    </div>
  );
};

function AboutUsDescription({ children }: { children: React.ReactNode }) {
  return (
    <p
      className={twMerge(
        "typography text-center md:text-left",
        rubik.className,
      )}
    >
      {children}
    </p>
  );
}

function AboutUsHeading({ children }: { children: React.ReactNode }) {
  return (
    <div className={twMerge("heading mx-auto md:mx-0", rubik.className)}>
      {children}
    </div>
  );
}

function AboutUsButton({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) {
  return (
    <Button
      asChild
      variant="hero_outline"
      className={twMerge(
        "font-extrabold italic text-lg rounded-xl px-16 py-7 mx-auto md:mx-0",
        rubik.className,
      )}
    >
      <Link href={href}>{children}</Link>
    </Button>
  );
}

function AboutUsCard({
  imageSrc,
  className,
  children,
}: {
  imageSrc: string;
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={twMerge(
        "flex flex-col md:flex-row gap-4 md:items-stretch",
        className || "",
      )}
    >
      <div className="md:w-1/2 flex-1">
        <div className="flex justify-center items-center w-full p-2 md:p-5"> 
          <div className="flex flex-col gap-4 items-start">{children}</div>
        </div>
      </div>

      <div className="md:w-1/2 md:max-w-1/2 flex-1 relative ">
        <Image
          className="object-cover w-full h-auto md:w-1/2 md:max-h-[75vh]" // Adjust width on medium screens
          src={imageSrc}
          alt="Description of the image"
          width={100}
          height={100}
        />
      </div>
    </div>
  );
}

export default AboutUs;
