"use client";


import { Rubik } from "next/font/google";
import { Card } from "@/components/ui/card";
import Image from "next/image";

const rubik = Rubik({
  subsets: ["latin"],
  variable: "--font-rubik",
});

interface InfoCardProps {
  title: string;
  image: string;
  description: string;
}

const InfoCard = (props: InfoCardProps) => {
  return (
    <div>
      <Card
        className={`flex flex-col gap-4 p-8 w-full h-full relative rounded-3xl ${rubik.className} font-sans font-bold bg-card`}
      >
        <div className="flex flex-row items-center gap-4">
          <Image
            className="h-[66px] w-[66px] p-2 rounded"
            src={props.image}
            alt="Info Card Image"
            width={100}
            height={100}
          />
          <div className="cardHeading text-3xl font-mono ">
            {props.title}
          </div>
        </div>
        <div className={"typography " + rubik.className}>{props.description}</div>
      </Card>
    </div>
  );
};

export default InfoCard;
