import Glow from "@/assets/glow.svg";
import Circles from "@/assets/images/circles.svg";
import PhoneApp from "@/assets/images/phone_app.png";
import Ladder from "@/assets/images/ladder.png";
import { Button } from "@/components/ui/button";
import { rubik } from "@/lib/fonts";
import { twMerge } from "@/lib/utils";
import Image from "next/image";
import { useCallback, useState } from "react";
import { useWeb3Authentication } from "@/context/web3AuthContext";
import { useRouter } from "next/navigation";

const Header = () => {
  const { login, userData, isLoading, web3Auth } = useWeb3Authentication();
  const [isLoginStarted, setIsLoginStarted] = useState(false);
  const router = useRouter();

  const handleLoginAndNavigate = useCallback(async () => {
    if (!userData || !userData.registered) {
      // Start login process if the user is not logged in
      if (!web3Auth) {
        console.error('Web3Auth not initialized');
        return;
      }

      setIsLoginStarted(true);
      try {
        const newUserData = await login();

        // If the user is registered, navigate to '/my-tickets'
        if (newUserData?.registered) {
          setIsLoginStarted(false);
          router.push('/my-tickets');
        } else {
          alert('Please complete the registration process to view your collectible.');
        }
      } catch (error) {
        console.error('Login failed:', error);
        alert('Login failed. Please try again.');
      } finally {
        setIsLoginStarted(false);
      }
    } else {
      // User is already logged in, navigate to '/my-tickets'
      router.push('/my-tickets');
    }
  }, [userData, login, router, web3Auth]);

  return (
    <div className="w-full relative h-screen max-w-screen overflow-display md:overflow-hidden bg-transparent">
      {/* Background Circles Image */}
      <Image className="absolute inset-0 w-full h-full object-cover" src={Circles.src} alt="Circles" width={1000} height={1000} />
      
      <div className="relative z-10 flex h-full w-full items-center justify-center px-5 sm:max-w-[90%] md:max-w-[80%] lg:max-w-[80%] mx-auto pt-36 md:pt-0">
        <div className="flex w-full sm:h-[90%] md:h-full flex-col justify-between sm:flex-row">
          
          {/* Text Section */}
          <div className="flex flex-col items-center sm:items-start sm:w-auto h-auto justify-center gap-5 p-0 text-center sm:text-left z-20">
            <div className={twMerge("text-4xl sm:text-6xl lg:text-6xl font-black uppercase italic", rubik.className)}>
              Welcome to Ticket Tree
            </div>
            <div className="border-l-4 border-brand_green px-5">
              <div className={twMerge("typography py-0.5", rubik.className)}>
                Ticket Tree gives fans the opportunity to earn rewards at their favorite events. Make each experience mean more.
              </div>
            </div>

            {/* Button to View My Collectible */}
            <Button 
              variant="primary" 
              className={twMerge("rounded-xl px-12 py-8 font-extrabold uppercase w-full", rubik.className)}
              onClick={handleLoginAndNavigate}
              disabled={isLoading || isLoginStarted} // Disable button while loading or during login
            >
              View My Collectibles
            </Button>
          </div>

          {/* Image Row Section */}
          <div className="flex flex-row justify-around items-center mt-20 sm:mt-0 sm:w-[90%] md:w-full md:pl-20 z-10">
            {/* Phone Application Image */}
            <Image
              className="z-10 w-full max-w-full max-h-80 sm:w-[45%] sm:max-h-full mb-5 sm:mb-0 hidden md:block" 
              src={PhoneApp.src}
              alt="Phone Application Interface"
              width={1000}
              height={1000}
            />
            {/* Ladder Image */}
            <Image
              className="z-10 w-full max-w-full max-h-80 sm:w-[45%] sm:max-h-full hidden md:block"
              src={Ladder.src}
              alt="Ladder Image"
              width={1000}
              height={1000}
            />
            {/* Green Glow effect */}
            <div className="absolute top-1/2 z-5 -translate-y-1/2 w-full h-[1000px] bg-greenGlow" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
