import { twMerge } from "@/lib/utils";

export function SnapToSection({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={twMerge(
        "z-10 relative md:h-screen md:snap-start md:snap-always",
        className || "",
      )}
    >
      {children}
    </div>
  );
}
