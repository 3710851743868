"use client";

import { FaInstagram, FaTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa';
import TTLogo from "@/assets/images/tt-logo-single.png";
import Image from "next/image";

const Footer = () => {
  return (
    <footer className="relative z-20 w-full bg-gradient-to-b from-tDarkGray to-tGreenTransparent">
      <div className="max-w-[1400px] m-auto flex flex-col md:flex-row justify-between py-20 px-10">
        <div className="max-w-[200px] w-full flex items-center gap-4 mb-4 md:mb-0 hidden md:block">
          <Image className="h-[50px] w-[50px]" src={TTLogo.src} alt="TT Logo" width={200} height={200} />
        </div>
        <div className="min-w-[200px] font-mono italic text-center md:text-left mb-4 md:mb-0">
          © 3 Tree Labs 2023
        </div>
        <div className="flex justify-around w-full md:w-auto">
          <a href="https://linkedin.com/company/tick3t-tree" target="_blank" rel="noopener noreferrer" className="flex items-center mx-2 md:mx-0">
            <FaLinkedin size={30} className="text-white" />
          </a>
          <a href="https://www.instagram.com/tick3ttree/" target="_blank" rel="noopener noreferrer" className="flex items-center mx-2 md:mx-0">
            <FaInstagram size={30} className="text-white" />
          </a>
          <a href="https://twitter.com/tickettreeapp" target="_blank" rel="noopener noreferrer" className="flex items-center mx-2 md:mx-0">
            <FaTwitter size={30} className="text-white" />
          </a>
          <a href="https://www.youtube.com/@tick3ttree" target="_blank" rel="noopener noreferrer" className="flex items-center mx-2 md:mx-0">
            <FaYoutube size={30} className="text-white" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
