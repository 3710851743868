import Dashboard2LinePng from "@/assets/images/dashboard-2-line.png";
import FundsLinePng from "@/assets/images/funds-line.png";
import GroupPng from "@/assets/images/group.png";
import LockLinePng from "@/assets/images/lock-line.png";
import InfoCard from "@/components/infoCard/InfoCard";
import { rubik } from "@/lib/fonts";
import { twMerge } from "@/lib/utils";

const Features = () => {
  return (
    <div className="bg-transparent w-full h-auto mt-10 px-5 py-5">
      <div className="flex flex-col sm:w-[90%] md:w-full gap-4 items-start">
        <div className={twMerge("heading text-white", rubik.className)}>
          Features
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-10 heading text-white  ">
          <InfoCard
            title="Value"
            image={GroupPng.src}
            description="Give more than just an event. Offer lasting memories with surprise game highlights on tickets."
          />
          <InfoCard
            title="Secure"
            image={LockLinePng.src}
            description="With blockchain-backed ticketing, experience no counterfeits, only secure and transparent access."
          />
          <InfoCard
            title="Highspeed"
            image={Dashboard2LinePng.src}
            description="Our swift ticket transactions guarantee attendees a quick, seamless, and hassle-free entry to events."
          />
          <InfoCard
            title="Analytics"
            image={FundsLinePng.src}
            description="Harness powerful analytics to optimize attendance, making every event a packed success."
          />
        </div>
      </div>
    </div>
  );
};

export default Features;
